var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"highlightBlock",class:[
    {
      'background-image': this.realImagePosition === 'background'
    },
    `highlight-block ${_vm.realContentPosition[0]}`
  ],style:(_vm.backgroundImageCss)},[_c('div',{class:[
      {
        'white-background-fade': _vm.highlightBlock.contentWhiteBackground,
        'dark-theme': _vm.darkTheme
      },
      `content-container flex ${_vm.realContentPosition[0]}`
    ]},[_c('div',{staticClass:"container-1/2"},[(_vm.highlightBlock.blockTitle)?_c('h3',{class:{ 'dark-theme': _vm.darkTheme },domProps:{"innerHTML":_vm._s(_vm.highlightBlock.blockTitle)}}):_vm._e(),_c('p',{class:{ 'dark-theme': _vm.darkTheme },domProps:{"innerHTML":_vm._s(_vm.highlightBlock.text)}}),(_vm.highlightBlock.buttonText && _vm.highlightBlock.buttonLink)?_c('AnchorButtonPrimary',{class:[{ 'dark-theme': _vm.darkTheme }, 'button'],attrs:{"button-text":_vm.highlightBlock.buttonText,"button-link":_vm.highlightBlock.buttonLink}}):_vm._e()],1)]),_c('div',{staticClass:"image-container flex",class:[
      _vm.realContentPosition[1],
      { 'side-image': _vm.realImagePosition === 'side' }
    ],style:(_vm.sideBackgroundImageCss)},[_c('div',{staticClass:"container-1/2"},[(_vm.realImagePosition === 'side')?_c('img',{attrs:{"src":_vm.imageUrl,"alt":_vm.imageTitle}}):_c('span',[_vm._v(" ")])])]),_c('div',{staticClass:"clear-both"})])
}
var staticRenderFns = []

export { render, staticRenderFns }
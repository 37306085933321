var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{class:{ 'mobile-menu-active': _vm.mobileMenuActive }},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"upper-container"},[_c('nav',{attrs:{"aria-label":"Secondary Navigation"}},_vm._l((_vm.secondaryNavItems),function(navItem){return _c('AnchorLink',{key:navItem.id,class:{ active: _vm.currentLink === navItem.hyperlink },attrs:{"link":navItem}})}),1)]),_c('div',{staticClass:"lower-container"},[_c('div',{staticClass:"site-logo"},[_c('router-link',{attrs:{"aria-label":"Back to Home","to":"/"}},[_c('img',{ref:"logoImage",attrs:{"src":require("@/assets/nav_logo.png"),"alt":_vm.logoImageTitle}})])],1),_c('button',{class:{ active: _vm.mobileMenuActive },attrs:{"aria-label":_vm.mobileMenuActive ? 'Close mobile menu' : 'Open mobile menu',"id":"mobileMenuButton"},on:{"click":function($event){_vm.mobileMenuActive = !_vm.mobileMenuActive}}},[_c('span',{staticClass:"bar"}),_c('span',{staticClass:"bar"}),_c('span',{staticClass:"bar"})]),_c('nav',{attrs:{"aria-label":"Main Navigation"}},[_vm._l((_vm.mainNavItems),function(navItem){return _c('div',{key:navItem.id,staticClass:"nav-link",on:{"mouseover":function($event){return _vm.showSubnavigation(navItem)},"focusin":function($event){return _vm.showSubnavigation(navItem)},"mouseout":function($event){return _vm.hideSubnavigation(navItem)},"focusout":function($event){return _vm.hideSubnavigation(navItem)},"click":function($event){return _vm.hideSubnavigation(navItem)}}},[_c('AnchorLink',{class:{ active: _vm.currentLink === navItem.hyperlink },attrs:{"link":navItem}}),(navItem.subNavigation.length)?_c('div',{class:[
              {
                hidden:
                  !_vm.isSubnavHiding(navItem) &&
                  !_vm.isSubnavShowing(navItem) &&
                  _vm.activeSubnav !== navItem.id
              },
              { hiding: _vm.isSubnavHiding(navItem) },
              { showing: _vm.isSubnavShowing(navItem) },
              'subnavigation'
            ]},[_c('div',{staticClass:"arrow-up"}),_vm._l((navItem.subNavigation),function(subNavItem){return _c('AnchorLink',{key:subNavItem.id,class:{ active: _vm.currentLink === subNavItem.hyperlink },attrs:{"link":subNavItem}})})],2):_vm._e()],1)}),_c('div',{staticClass:"nav-link"},[_c('router-link',{attrs:{"to":{ name: 'Search' }}},[_c('font-awesome-icon',{staticClass:"search-icon",attrs:{"icon":"search"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Search")])],1)],1)],2)]),_c('div',{ref:"mobileMenuContainer",staticClass:"mobile-menu"},[_c('nav',{staticClass:"main-menu",attrs:{"aria-label":"Main Navigation"}},_vm._l((_vm.mainNavItems),function(navItem){return _c('div',{key:navItem.id,staticClass:"nav-link"},[(navItem.subNavigation.length)?_c('font-awesome-icon',{class:[
              { open: _vm.mobileSubNavShowing.indexOf(navItem.id) !== -1 },
              'sub-nav-toggle'
            ],attrs:{"role":"button","aria-label":`${
                _vm.mobileSubNavShowing.indexOf(navItem.id) !== -1
                  ? 'open'
                  : 'close'
              } sub navigation ${navItem.linkTitle}`,"icon":'chevron-right'},on:{"click":function($event){_vm.mobileSubNavShowing.indexOf(navItem.id) === -1
                ? _vm.openMobileSubnav(navItem)
                : _vm.closeMobileSubnav(navItem)}}}):_vm._e(),_c('AnchorLink',{class:{ active: _vm.currentLink === navItem.hyperlink },attrs:{"link":navItem}}),(navItem.subNavigation.length)?_c('div',{class:[
              { open: _vm.mobileSubNavShowing.indexOf(navItem.id) !== -1 },
              'sub-navigation'
            ]},_vm._l((navItem.subNavigation),function(subNavItem){return _c('AnchorLink',{key:subNavItem.id,class:{ active: _vm.currentLink === subNavItem.hyperlink },attrs:{"link":subNavItem}})}),1):_vm._e()],1)}),0),_c('span',{staticClass:"separator"}),_c('nav',{staticClass:"secondary-menu",attrs:{"aria-label":"Secondary Navigation"}},_vm._l((_vm.secondaryNavItems),function(navItem){return _c('AnchorLink',{key:navItem.id,class:{ active: _vm.currentLink === navItem.hyperlink },attrs:{"link":navItem}})}),1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }